import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import {
    Grid, TableBody, TableRow, TableCell, Table, TableHead, TableFooter,
    TablePagination, MenuItem, CircularProgress, List, ListItem, Button,
    Checkbox, IconButton, Dialog, Snackbar, SnackbarContent
} from '@material-ui/core';
// import { Link } from 'react-router-dom'
import _ from 'lodash'
import {
    processingFetchList, processingQueryParams, resubmitProcessing,
    resubmitLMSProcessing, resubmitClusterProcessing, processingUpdate,
    firstSubmitClusterProcessing, resubmitEOProcessing, cancelLMSProcessing
} from './ProcessingActions'
import ProcessingStatusTableIcon from './ProcessingStatusTableIcon';
import TableEllipsisIcon from '../common/TableEllipsisIcon';
import CalibrationStatusTableIcon from './CalibrationStatusTableIcon';
import ConfirmationDialog from '../common/ConfirmationDialog';
import BlockIcon from '@material-ui/icons/Block';
import Config from '../app/Config.json'
import LMSProcessingStatusTableIcon from './LMSProcessingStatusTableIcon';
import DynamicClusterStatusIcon from '../common/DynamicClusterStatusIcon';
import { sensorEquipmentFetchList } from './SensorEquipmentActions';
import { clearAllFilters, uniqueValuesFetch } from '../common/CompositeFilterActions'
import {
    getBrowserVisibilityProp, getIsDocumentHidden
} from '../common/pageVisibilityHelper'
import CancelIcon from '@material-ui/icons/Cancel';
import Search from '@material-ui/icons/Search'
import InfoIcon from '@material-ui/icons/Info';
import NicerTooltip from '../common/NicerTooltip';
import humanizeDuration from 'humanize-duration';
import QALogDialog from '../common/QALogDialog';
import { handleValidateArchive } from '../explorer/objectExplorerActions'
import SearchBar from '../common/SearchBar';
import FilterDialog from './FilterDialog';
import './processing.css'
// import RouteIcon from '@material-ui/icons/route'
import { Route } from '../icons/svgIcons'
import FlightLineMapDialog from './FlightLineMapDialog'
import { isMemberOfGuestGroups } from '../common/PrivateRoute'

class ProcessingList extends Component {

    constructor(props) {
        super(props);
        // this.getProcessing();
        // this.state.processing = this.props.processing || [];
        this.willUnblockAfterPolling = createRef({})
        this.targetArchivedS3Link = createRef('')           // temp store the ultimate archive s3 path
        this.filterDialogRef = createRef(null)
    }

    state = {
        queryObject: {},
        processing: [],
        // reloadTable: false,
        resubmitDialogOpen: false,
        QALogDialogOpen: false,
        displayDialogOpen: false,
        markCompletedDialogOpen: false,
        cancelDialogOpen: false,
        pollingTimeoutId: null,
        polling: false,
        filterLoading: false,
        processingTemplates: [],
        projectManagers: [],
        // rowsPerPage: 10,
        isNeedFirstPollingArrived: {},              // recovery icons once the first polling response received after resubmitting jobs
        isPageVisible: !getIsDocumentHidden(),
        isValidateArchiveDialogOpen: false,
        isFilterDialogOpen: false,
        isFlighLineDialogOpen: false,
        queryFilters: {},
        isFlightLinesDialogOpen: true,
        fligtlineDialogProcess: null,
        filterOnFocusIndex: -1, 
        resubmittedJobProcessingTemplate: null,
        resubmittedJobDocumentMajorVersion: null,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.projectManagers.length !== 0 && this.state.projectManagers.length === 0) {
            this.setState({ projectManagers: this.props.projectManagers })
        }
        if (this.props.processingTemplates.length !== 0 && this.state.processingTemplates.length === 0) {
            let emptyRemoved = this.props.processingTemplates.filter(item => item.value !== "");
            if (emptyRemoved.length > 0)
                this.setState({ processingTemplates: emptyRemoved });
        }
        //triggering reload of the table when processing is resubmitted
        //not necessary if polling is enabled though I suspect
        // if (this.state.reloadTable && prevState.reloadTable === false) {
        //     this.getProcessing();
        // }
        // console.log('currentLMSVersion',this.props.currentLMSVersion)

        if (!this.props.processingListLoading && prevProps.processingListLoading && this.state.isPageVisible) {
            if (this.state.pollingTimeoutId) {
                clearTimeout(this.state.pollingTimeoutId);
                this.state.pollingTimeoutId = null
                this.state.polling = false;
                this.state.filterLoading = false;
            }

            if (this.willUnblockAfterPolling && this.willUnblockAfterPolling.current && Object.keys(this.willUnblockAfterPolling.current).length) {
                let newIsNeedFirstPollingArrived = { ...this.state.isNeedFirstPollingArrived }
                Object.keys(this.willUnblockAfterPolling.current).forEach(pid => {
                    delete newIsNeedFirstPollingArrived[pid]
                })
                this.setState({ isNeedFirstPollingArrived: newIsNeedFirstPollingArrived })
            }
            this.willUnblockAfterPolling.current = {}

            this.state.pollingTimeoutId = setTimeout(() => {
                this.willUnblockAfterPolling.current = this.state.isNeedFirstPollingArrived
                this.state.polling = true;
                this.getProcessing();
            }, Config.MODERATE_POLLING);
        }

        let newIsNeedFirstPollingArrived = { ...this.state.isNeedFirstPollingArrived }
        if (!_.isEqual(prevProps.processingResubmitLoadingWithId, this.props.processingResubmitLoadingWithId)) {
            Object.keys(prevProps.processingResubmitLoadingWithId).forEach((processingId) => {
                if (!(processingId in this.props.processingResubmitLoadingWithId)) {
                    newIsNeedFirstPollingArrived[processingId] = true
                }
            })
        }
        if (!_.isEqual(prevProps.processingLMSResubmitLoadingWithId, this.props.processingLMSResubmitLoadingWithId)) {
            Object.keys(prevProps.processingLMSResubmitLoadingWithId).forEach((processingId) => {
                if (!(processingId in this.props.processingLMSResubmitLoadingWithId)) {
                    newIsNeedFirstPollingArrived[processingId] = true
                }
            })
        }
        if (!_.isEqual(prevProps.processingClusterResubmitLoadingWithId, this.props.processingClusterResubmitLoadingWithId)) {
            Object.keys(prevProps.processingClusterResubmitLoadingWithId).forEach((processingId) => {
                if (!(processingId in this.props.processingClusterResubmitLoadingWithId)) {
                    newIsNeedFirstPollingArrived[processingId] = true
                }
            })
        }
        if (!_.isEqual(prevState.isNeedFirstPollingArrived, newIsNeedFirstPollingArrived)) {
            this.setState({ isNeedFirstPollingArrived: newIsNeedFirstPollingArrived })
        }
        if (prevProps.explorerStore.isValidatingArchive !== this.props.explorerStore.isValidatingArchive) {
            this.setState({ isValidateArchiveDialogOpen: this.props.explorerStore.isValidatingArchive })
            if (!this.props.explorerStore.isValidatingArchive) {
                this.setState({ isValidateArchiveSnackBarOpen: true })
                if (this.props.explorerStore.validateArchiveSucceed && this.props.explorerStore.isArchivedSucceed && this.targetArchivedS3Link) {
                    const archiveS3PathSplited = this.targetArchivedS3Link.current.split('/')
                    let archiveBucket = archiveS3PathSplited[0]
                    let archivePrefix = archiveS3PathSplited.slice(1).join('/') + (this.targetArchivedS3Link.current.endsWith('/') ? '' : '/')
                    let uri = encodeURI(`/explorer?bucket=${archiveBucket}&prefix=${archivePrefix}`)
                    window.open(uri)
                }
            }
        }
        if (prevState.queryFilters !== this.state.queryFilters) {
            this.queryFiltersOnChange()
        }
    }

    componentWillUnmount() {
        if (this.state.pollingTimeoutId) {
            clearTimeout(this.state.pollingTimeoutId);
            this.state.pollingTimeoutId = null
            this.state.polling = false;
        }
        const visibilityChange = getBrowserVisibilityProp()
        document.removeEventListener(visibilityChange, this.onVisibilityChange)
    }

    // static getDerivedStateFromProps(nextProps, prevState) {

    //     // if (nextProps.pollingTimeoutId){

    //     // }
    //     // if(nextProps.processingListLoading && (prevState.pollingTimeoutId || nextProps.pollingTimeoutId)){
    //     //     clearTimeout(prevState.pollingTimeoutId);
    //     //     clearTimeout(nextProps.pollingTimeoutId);
    //     // }

    //     if (nextProps.processing !== null && nextProps.processing.length > 0){
    //         console.log("test 1")
    //         return {
    //             processing: nextProps.processing.splice(0, nextProps.processing.length),
    //             // loading: nextProps.processingResubmitLoadingprocessingSubmitLoading
    //             reloadTable: nextProps.processingSubmitSuccessful,
    //             // pollingTimeoutId: nextProps.pollingTimeoutId ? nextProps.pollingTimeoutId : null
    //         }
    //     }

    //     console.log("test 2")
    //     return {
    //         processing: [],
    //         reloadTable: nextProps.processingSubmitSuccessful,
    //         // pollingTimeoutId: nextProps.pollingTimeoutId ? nextProps.pollingTimeoutId : null
    //     }
    // }

    componentDidMount() {
        //console.clear()
        this.clearAllFilters();
        this.getProcessing();
        this.getSensors();
        this.getProjectManagers();
        this.getProcessingTemplates();
        const visibilityChange = getBrowserVisibilityProp()
        // document.addEventListener(visibilityChange, this.onVisibilityChange, false)
        document.addEventListener(visibilityChange, this.onVisibilityChange)
    }

    clearAllFilters = () => {
        this.setState({ queryObject: {} }, this.props.setQueryParams({ params: {}, page: 1 }));//not maintaining filters between page loads
        this.props.clearAllFilters();
    }

    getProcessing = () => {
        this.props.fetchData(this.props.token);
    }

    getSensors = () => {
        if (this.props.sensorEquipment.length === 0 && !this.props.sensorEquipmentListLoading)
            this.props.fetchSensors(this.props.token);
    }

    getProjectManagers = () => {
        this.props.token && this.props.fetchUniqueValues(this.props.token, "project", "ProjectManager");
    }

    getProcessingTemplates = () => {
        this.props.token && this.props.fetchUniqueValues(this.props.token, "project", "ProcessingTemplate");
    }

    changePage = (evt, page) => {
        page = page + 1
        this.props.setQueryParams({ page })
        // this.setState({ rowsPerPage: 10 });
    }

    queryFiltersOnChange = () => {
        let queryParams = this.generateQueryParams()
        if (this.timerInterval) {
            clearTimeout(this.timerInterval);
        }

        this.timerInterval = setTimeout(() => {
            this.props.setQueryParams({ params: queryParams, page: 1 })
            this.setState({ filterLoading: true })
        }, 1000);
    }

    formatTextListQueryParam = (queryFilters, filterName, keyName) => {
        if (queryFilters[filterName] && queryFilters[filterName].length) {
            return { [keyName]: '$any' + queryFilters[filterName].join(',') }
        } else {
            return {}
        }
    }

    formatDateQueryParam = (queryFilters, dateStartFilterName, dateEndFilterName, keyName) => {
        if (queryFilters[dateStartFilterName] && queryFilters[dateEndFilterName]) {
            return { [keyName]: `${queryFilters[dateStartFilterName]}+${queryFilters[dateEndFilterName]}` }
        } else {
            return {}
        }
    }

    formatPrimaryIdQueryParam = (queryFilters, filterName) => {
        const SENSOR_ID_KEY_NAME = 'SensorID'
        const PROJECT_ID_KEY_NAME = 'ProjectID'
        const FLIGHT_ID_KEY_NAME = 'FlightID'
        const primaryIdFilterList = queryFilters[filterName] || []
        let sensor_id_list = []
        let project_id_list = []
        let flight_id_list = []
        primaryIdFilterList.forEach(primaryId => {
            if (primaryId.startsWith('SL')) sensor_id_list.push(primaryId)
            if (primaryId.startsWith('PRJ')) project_id_list.push(primaryId)
            if (primaryId.startsWith('FL')) flight_id_list.push(primaryId)
        })
        let queryParam = {}
        if (sensor_id_list.length) queryParam[SENSOR_ID_KEY_NAME] = `$any${sensor_id_list.join(',')}`
        if (project_id_list.length) queryParam[PROJECT_ID_KEY_NAME] = `$any${project_id_list.join(',')}`
        if (flight_id_list.length) queryParam[FLIGHT_ID_KEY_NAME] = `$any${flight_id_list.join(',')}`
        return queryParam
    }

    generateQueryParams = () => {
        let primaryIdQueryParam = this.formatPrimaryIdQueryParam(this.state.queryFilters, 'primaryIdFilterList')
        let aircraftQueryParam = this.formatTextListQueryParam(this.state.queryFilters, 'aircraftFilterList', 'Aircraft')
        let sensorQueryParam = this.formatTextListQueryParam(this.state.queryFilters, 'sensorFilterList', 'Sensor')
        let projectManagerQueryParam = this.formatTextListQueryParam(this.state.queryFilters, 'projectManagerFilterList', 'ProjectManager')
        let processingTemplateQueryParam = this.formatTextListQueryParam(this.state.queryFilters, 'processingTemplateFilterList', 'ProcessingTemplate')
        let surveyDateQueryParam = this.formatDateQueryParam(this.state.queryFilters, 'surveyDateStart', 'surveyDateEnd', 'Date')
        let queryParams = {
            ...aircraftQueryParam, ...sensorQueryParam, ...projectManagerQueryParam,
            ...processingTemplateQueryParam, ...surveyDateQueryParam, ...primaryIdQueryParam
        }
        return queryParams
    }

    showResubmitDialog = (SensorID, BaseFolder, id, process) => {

        let resubmitCallback = () => {
            let jsonDoc = {
                "SensorID": SensorID,
                "BaseFolder": BaseFolder
            };
            this.props.resubmitGPSProcessing(this.props.token, jsonDoc, id);
            this.setState({
                // resubmitDialogOpen: true,
                submitCallback: null
            });

        }

        this.setState({
            resubmitDialogOpen: true,
            submitCallback: resubmitCallback, 
            resubmittedJobProcessingTemplate: process.ProcessingTemplate,
            resubmittedJobDocumentMajorVersion: process.DocumentMajorVersion
        });
    }

    updateQAStatus = (prefix, id, jsonDoc) => {

        let updateCallback = (issueDesc, issueRes) => {

            jsonDoc[prefix + "QAIssueDesc"] = issueDesc;
            jsonDoc[prefix + "QAIssueRes"] = issueRes;
            // console.log('...', id, jsonDoc);

            this.props.updateProcessing(this.props.token, id, jsonDoc);

            this.setState({
                submitCallback: null,
                QALogDialogOpen: false
            });
        }

        this.setState({
            QALogDialogOpen: true,
            submitCallback: updateCallback
        });
    }

    // updateProcessing: (token, id, jsonData)
    //TODO: remove, its redudnant now
    updateProcessingStatus = (id, statusField, status) => {

        let updateCallback = () => {
            let jsonDoc = {};
            jsonDoc[statusField] = status;

            console.log('...', jsonDoc);

            this.props.updateProcessing(this.props.token, id, jsonDoc);
            this.setState({
                submitCallback: null
            });

        }

        this.setState({
            markCompletedDialogOpen: true,
            submitCallback: updateCallback
        });
    }

    //has no confirmation dialog, unlike updateProcessingStatus
    updateProcessingValue = (id, fieldName, value) => {
        let jsonDoc = {};
        jsonDoc[fieldName] = value;
        // console.log('...', jsonDoc);
        this.props.updateProcessing(this.props.token, id, jsonDoc);
    }

    updateProcessingValues = (id, jsonDoc) => {
        this.props.updateProcessing(this.props.token, id, jsonDoc);
    }

    //has no confirmation dialog, unlike updateProcessingStatus
    updateClusterValue = (id, clusterName, fieldName, value) => {
        let jsonDoc = {};
        jsonDoc[fieldName] = value;

        this.props.updateProcessing(this.props.token, id, jsonDoc, clusterName);
    }

    updateClusterValues = (id, clusterName, jsonDoc) => {
        this.props.updateProcessing(this.props.token, id, jsonDoc, clusterName);
    }

    showLMSResubmitDialog = (id, process) => {

        let resubmitCallback = () => {

            this.props.resubmitLMSProcessing(this.props.token, id);
            this.setState({
                submitCallback: null
            });

        }
        this.setState({
            resubmitDialogOpen: true,
            submitCallback: resubmitCallback,
            resubmittedJobProcessingTemplate: process.ProcessingTemplate,
            resubmittedJobDocumentMajorVersion: process.DocumentMajorVersion
        });
    }

    showLMSCancelDialog = (id, process) => {

        let cancelCallback = () => {

            this.props.cancelLMSProcessing(this.props.token, id);
            this.setState({
                cancelCallback: null
            });

        }
        this.setState({
            cancelDialogOpen: true,
            cancelCallback: cancelCallback,
            // resubmittedJobProcessingTemplate: process.ProcessingTemplate,
            // resubmittedJobDocumentMajorVersion: process.DocumentMajorVersion
        });
    }

    showEOResubmitDialog = (id) => {

        let resubmitCallback = () => {

            this.props.resubmitGPSEOProcessing(this.props.token, id);
            this.setState({
                submitCallback: null
            });

        }
        this.setState({
            resubmitDialogOpen: true,
            submitCallback: resubmitCallback
        });
    }

    showClusterPipelineResubmitDialog = (id) => {
        let resubmitCallback = () => {
            this.props.firstSubmitClusterProcessing(this.props.token, id);
            this.setState({
                submitCallback: null
            });
        }
        this.setState({
            resubmitDialogOpen: true,
            submitCallback: resubmitCallback
        });
    }

    // gpsResubmit = (SensorID, BaseFolder, id) => {

    //     this.setState({ resubmitDialogOpen: true });

    //     // let jsonDoc = {
    //     //     "SensorID": SensorID,
    //     //     "BaseFolder": BaseFolder
    //     // };
    //     // this.props.resubmitProcessing(this.props.token, jsonDoc, id);
    // }

    getFlightValues = (processing, key) => {
        let values = processing.Flights.map(s => s[key])
            .filter(v => v != null && v != 'null')
        values = values.filter((item, pos) => values.indexOf(item) == pos) //remove duplicated
        return values;
    }

    isEquivalent = (a, b) => {
        let aProps = Object.getOwnPropertyNames(a);
        let bProps = Object.getOwnPropertyNames(b);

        if (aProps.length != bProps.length) {
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];

            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        return true;
    }

    //a stopgap, until the cluster icons are refactored
    getClusterValue = (processDoc, clusterName, value) => {
        for (var i = 0; i < processDoc.ClusterProcessing.length; i++) {
            if (clusterName === processDoc.ClusterProcessing[i].Name)
                return processDoc.ClusterProcessing[i][value]
        }
    }

    onVisibilityChange = () => {
        const isHidden = getIsDocumentHidden()
        if (this.state.pollingTimeoutId && isHidden) {
            clearTimeout(this.state.pollingTimeoutId);
            this.state.pollingTimeoutId = null
            this.state.polling = false;
            this.state.filterLoading = false;
        }
        this.setState({ isPageVisible: !isHidden })
        if (!isHidden && !this.props.processingListLoading) {
            this.getProcessing();
        }
    }

    handleExplorerOnClick = (type, processingId, archivedS3Link) => {
        // console.log(processingId, archivedS3Link)
        this.targetArchivedS3Link.current = archivedS3Link
        let sourceBucket = Config.PROCESSING_BUCKET
        let sourcePrefix = `${processingId}/`
        this.props.handleValidateArchive(sourceBucket, sourcePrefix)
    }

    handleValidateArchiveSnackBarOnClose = () => {
        this.setState({ isValidateArchiveSnackBarOpen: false })
    }

    handleFilterDialogOnClose = () => {
        this.setState({ isFilterDialogOpen: false })
    }

    handleOpenFilterDialog = (index = -1) => {
        this.setState({ isFilterDialogOpen: true })
        if (index >= 0) {
            let originalFilterIndex = ((index - 2) > 0) ? (index - 2) : 0
            this.setState({ filterOnFocusIndex: originalFilterIndex })
        } else {
            this.setState({ filterOnFocusIndex: -1 })
        }
    }

    handleUpdateQueryFilters = (queryFilters) => {
        this.setState({ queryFilters: queryFilters })
    }

    getSecondaryFilterList = (queryFilters) => {
        return ([
            { name: 'Aircraft', counter: queryFilters.aircraftFilterList ? queryFilters.aircraftFilterList.length : 0 },
            { name: 'Sensor', counter: queryFilters.sensorFilterList ? queryFilters.sensorFilterList.length : 0 },
            { name: 'Project Manager', counter: queryFilters.projectManagerFilterList ? queryFilters.projectManagerFilterList.length : 0 }
        ])
    }

    getOtherFilterList = (queryFilters) => {
        const isSurveyDateStartDefined = !!queryFilters.surveyDateStart
        const isSurveyDateEndDefined = !!queryFilters.surveyDateEnd
        const surveyDateFilterCounter = (isSurveyDateStartDefined && isSurveyDateEndDefined) ? 1 : 0
        return ([
            { name: 'Survey Date', counter: surveyDateFilterCounter },
            { name: 'Processing Template', counter: queryFilters.processingTemplateFilterList ? queryFilters.processingTemplateFilterList.length : 0 },
        ])
    }

    handleFlightLineDialogOnClose = () => {
        this.setState({ isFlighLineDialogOpen: false })
    }

    handleFlightLineDialogOpen = (process) => {
        // console.log("...process",process)
        // process.FlightID, process.SensorID, process.ProjectID, process.Aircraft, process.Sensor, process.ProjectName
        this.setState({ isFlighLineDialogOpen: true, fligtlineDialogProcess: process })
    }
    sortAllFilterList = (queryFilters) => {
        const isSurveyDateStartDefined = !!queryFilters.surveyDateStart
        const isSurveyDateEndDefined = !!queryFilters.surveyDateEnd
        return [
            {
                name: 'Flight Log', filterList: queryFilters.primaryIdFilterList
                    ? queryFilters.primaryIdFilterList.filter(filter => filter.startsWith('FL'))
                    : []
            },
            {
                name: 'Sensor Log', filterList: queryFilters.primaryIdFilterList
                    ? queryFilters.primaryIdFilterList.filter(filter => filter.startsWith('SL'))
                    : []
            },
            {
                name: 'Project Log', filterList: queryFilters.primaryIdFilterList
                    ? queryFilters.primaryIdFilterList.filter(filter => filter.startsWith('PRJ'))
                    : []
            },
            { name: 'Aircraft', filterList: queryFilters.aircraftFilterList },
            {
                name: 'Survey Date', filterList: (isSurveyDateStartDefined && isSurveyDateEndDefined)
                    ? [
                        `Start: ${queryFilters.surveyDateStart}`, `End: ${queryFilters.surveyDateEnd}`
                    ]
                    : []
            },
            { name: 'Sensor', filterList: queryFilters.sensorFilterList },
            { name: 'Project Manager', filterList: queryFilters.projectManagerFilterList },
            { name: 'Processing Template', filterList: queryFilters.processingTemplateFilterList }
        ]
    }

    clearAllFilters = () => {
        this.setState({ queryFilters: {} })
    }

    isAnyFilterApplied = (queryFilters) => {
        if (queryFilters && Object.keys(queryFilters).length) {
            return Object.keys(queryFilters).some(queryFilterKey => queryFilters[queryFilterKey].length)
        } else {
            return false
        }
    }

    render() {

        // if (this.props.processingListLoading && this.state.polling === false)
        //     return (
        //         <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
        //             <CircularProgress />
        //             <div style={{ paddingTop: 5 }}>Loading Processes...</div>
        //         </Grid>
        //     )


        let processing = this.props.processing || [];
        // let processing = this.state.processing;
        let queryParams = this.props.queryParams;

        const flightIdColumnStyle = { textAlign: "center", width: 80 };
        const dateColumnStyle = { textAlign: "center", width: 70 };
        const timeColumnStyle = { textAlign: "center", width: 80 };
        const statusColumnStyle = { textAlign: "center", width: 50 };
        const sensorNameColumnStyle = { textAlign: "center", width: 150 };
        const projectNameColumnStyle = { textAlign: "center", width: 280 };
        const pipelineColumnStyle = { textAlign: "center", width: 625 };
        const headerDivTextStyle = { fontWeight: "bolder", color: "#000000" };

        const currentLMSVersion = this.props.currentLMSVersion;
        const user = this.props.user;

        return (
            <>
                {
                    this.isAnyFilterApplied(this.state.queryFilters)
                    && <SearchBar
                        width={1410}
                        filtersBtnOnClick={this.handleOpenFilterDialog}
                        sortedFilterList={this.sortAllFilterList(this.state.queryFilters)}
                        clearAllFiltersCallBack={this.clearAllFilters}
                    />
                }
                <div className='mt-2'>
                    {
                        // this.props.processingListLoading && this.state.polling === false &&
                        ((this.props.processingListLoading && this.state.polling === false) || this.state.filterLoading) &&
                        <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                            <CircularProgress />
                            <div style={{ paddingTop: 5 }}>Loading Processes...</div>
                        </Grid>
                    }

                    <Grid container direction='row' justify='center' alignItems='flex-start' xs={12}>
                        <div style={{ width: 1345 }} >
                            <Grid container direction="column" alignItems="center" justify="center"
                                style={{ width: 1245, display: (this.props.processingListLoading && !this.state.polling) || this.state.filterLoading ? 'none' : 'inline' }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow style={{ height: 65 }}>
                                            <TableCell padding="none" style={flightIdColumnStyle}>
                                                <div style={headerDivTextStyle}>
                                                    <div style={{ marginBottom: 2 }} >Sensor ID</div>
                                                    <div style={{ marginBottom: 2 }} >Flight ID</div>
                                                    <div>Project ID</div>
                                                </div>
                                            </TableCell>
                                            <TableCell padding="none" style={flightIdColumnStyle}>
                                                <div style={headerDivTextStyle}>
                                                    <div style={{ marginBottom: 6 }} >Aircraft</div>
                                                    <div>Sensor</div>
                                                </div>
                                            </TableCell>
                                            {/* <TableCell padding="none" style={sensorNameColumnStyle}><div style={headerDivTextStyle}>Sensor</div></TableCell> */}
                                            <TableCell padding="none" style={dateColumnStyle}><div style={headerDivTextStyle}>Survey Date<br />(UTC)</div></TableCell>
                                            <TableCell padding="none" style={projectNameColumnStyle}>
                                                <div style={headerDivTextStyle}>
                                                    <div style={{ marginBottom: 6 }} >Project Name</div>
                                                    <div>Client Name</div>
                                                </div>
                                            </TableCell>
                                            <TableCell padding="none" style={statusColumnStyle}><div style={headerDivTextStyle}>Flight<br />Lines</div></TableCell>
                                            <TableCell padding="none" style={statusColumnStyle}><div style={headerDivTextStyle}>Calib.<br />Params.</div></TableCell>
                                            <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Processing<br />Template</div></TableCell>
                                            <TableCell padding="none" style={statusColumnStyle}><div style={headerDivTextStyle}></div></TableCell>
                                            <TableCell padding="none" style={statusColumnStyle}><div style={headerDivTextStyle}></div></TableCell>
                                            <TableCell padding="none" style={pipelineColumnStyle}><div style={headerDivTextStyle}>Pipeline Status</div></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { //!this.props.processingListLoading && //!this.state.polling &&
                                            React.Children.toArray(
                                                processing.map((process, index) => {
                                                    const isGPSResubmitted = (process._id in this.props.processingResubmitLoadingWithId) ? true : false
                                                    const isLMSResubmitted = (process._id in this.props.processingLMSResubmitLoadingWithId) ? true : false
                                                    const isClusterResubmitted = (process._id in this.props.processingClusterResubmitLoadingWithId) ? true : false
                                                    const isAnyResubmitted = isGPSResubmitted || isLMSResubmitted || isClusterResubmitted
                                                    const isWaitingForPollingResponse = (process._id in this.state.isNeedFirstPollingArrived) ? true : false
                                                    const clientId = process.Project[0].Client.split(' ')[0]
                                                    const isEoOnly = process.CustomProcessing && process.CustomProcessing.eo_only ? true : false

                                                    // A bool value to indicate if there is GPS, LMS or dynamic cluster is on RUNNING or QUEUED state
                                                    // GPS is the first cluster. So there is no downstream icon that have the context menu.
                                                    const isRunningStatus = (status) => ['RUNNING', 'QUEUED'].indexOf(status) > -1
                                                    // const isGPSRunning = isRunningStatus(process.GPSProcStatus)
                                                    const isLMSRunning = isRunningStatus(process.LMSProcStatus)
                                                    const isClusterRunning = process.ClusterProcessing
                                                        // .filter(cluster => cluster.Category=='IP')
                                                        .map((cluster) => cluster.Status)
                                                        .some(isRunningStatus)
                                                    const isAnyRunningJob = isLMSRunning || isClusterRunning           // LMS and dynamic clusters

                                                    return (
                                                        <TableRow style={{ height: 65 }} key={index}>
                                                            <TableCell padding="none" style={flightIdColumnStyle}>
                                                                {/* <div style={{ marginBottom: 2 }} >
                                                                    <NicerTooltip placement="right"
                                                                        content={
                                                                            <span>
                                                                                <strong>Flight ID:</strong>&nbsp;{process.FlightID}
                                                                            </span>
                                                                        }>
                                                                        {process.SensorID}
                                                                    </NicerTooltip>
                                                                </div> */}
                                                                <div style={{ marginBottom: 2 }}>{process.SensorID}</div>
                                                                <div style={{ marginBottom: 2 }}>{process.FlightID}</div>
                                                                <div>
                                                                    <NicerTooltip placement="right"
                                                                        content={
                                                                            <span>
                                                                                {/* <strong>Project Name:</strong>&nbsp;{process.ProjectName}<br /> */}
                                                                                <strong>Project Status:</strong>&nbsp;{process.ProjectStatus}
                                                                                {process.ProjectStatus === 'Closed'
                                                                                    && <>
                                                                                        <br /><strong>LiDAR Archive Status:</strong>&nbsp;{process.archiveInPlaceCompleteLidar ? 'All data archived' : 'Incomplete'}
                                                                                    </>
                                                                                }
                                                                            </span>
                                                                        }>
                                                                        {process.ProjectID}
                                                                    </NicerTooltip>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell padding="none" style={flightIdColumnStyle}>
                                                                <div style={{ marginBottom: 6 }} >
                                                                    {process.Aircraft}
                                                                </div>
                                                                <NicerTooltip placement="right"
                                                                    content={
                                                                        <span>
                                                                            <strong>Sensor:</strong>&nbsp;{process.Sensor}
                                                                        </span>
                                                                    }>
                                                                    <div>
                                                                        {process.Sensor.slice(-3)}
                                                                    </div>
                                                                </NicerTooltip>
                                                            </TableCell>
                                                            {/* <TableCell padding="none" style={sensorNameColumnStyle}>{process.Sensor}</TableCell> */}
                                                            <TableCell padding="none" style={dateColumnStyle}>
                                                                <NicerTooltip placement="right"
                                                                    content={
                                                                        <span>
                                                                            <strong>Wheels Up:</strong>&nbsp;{process.WheelsUp.replace("T", ' ')} (UTC)<br />
                                                                            <strong>Wheels Down:</strong>&nbsp;{process.WheelsDown.replace("T", ' ')} (UTC)
                                                                        </span>
                                                                    }>
                                                                    <div>
                                                                        {process.Date.split("T")[0]}
                                                                    </div>
                                                                </NicerTooltip>
                                                            </TableCell>
                                                            <TableCell padding="none" style={projectNameColumnStyle}>

                                                                <div
                                                                    className='table_cell__box--prj'
                                                                    style={{ marginBottom: 6 }}
                                                                >
                                                                    <div className='table_cell--prj_overflow'>
                                                                        <NicerTooltip placement="right"
                                                                            content={
                                                                                <span>
                                                                                    <strong>Project Name:</strong>&nbsp;{process.ProjectName}<br />
                                                                                    <strong>Project Manager:</strong>&nbsp;{process.ProjectManager}
                                                                                </span>
                                                                            }>
                                                                            <div>
                                                                                {process.ProjectName}
                                                                            </div>
                                                                        </NicerTooltip>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <NicerTooltip placement="right"
                                                                        content={
                                                                            <span>
                                                                                <strong>Client Name:</strong>&nbsp;{process.Project[0].Client}
                                                                            </span>
                                                                        }>
                                                                        <div>
                                                                            {clientId}
                                                                        </div>
                                                                    </NicerTooltip>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell padding="none" style={statusColumnStyle}>
                                                                {
                                                                    !process.isSplitFlight &&
                                                                    <NicerTooltip placement="right"
                                                                        content={
                                                                            <span>Not Applicable</span>
                                                                        }>
                                                                        <span style={{ fontWeight: "bold", color: '#1a3a69' }}>NA</span>
                                                                    </NicerTooltip>
                                                                }
                                                                {
                                                                    process.isSplitFlight
                                                                        && <NicerTooltip placement="right"
                                                                            content={
                                                                                <div>
                                                                                    {
                                                                                        !process.isArchived 
                                                                                            && <>
                                                                                                <span style={{ fontStyle: "italic" }}>Click to view Flight Lines Map</span>
                                                                                                <br/>
                                                                                            </>
                                                                                    }
                                                                                    <span >AOI No.: {process.ProjectAOINo}</span>
                                                                                </div>
                                                                            }
                                                                        >
                                                                        <IconButton
                                                                            onClick={() => this.handleFlightLineDialogOpen(process)}
                                                                            disabled={process.isArchived}
                                                                        >
                                                                            <Route color='#1a3a69' />
                                                                            <div style={{
                                                                                    width: 12, height: 12, backgroundColor: "#1a3a69", borderRadius: "50%", border: "2px solid #1a3a69", color: "#FFFFFF",
                                                                                    fontWeight: "bold", fontSize: 10, position: "absolute", marginTop: -38, marginLeft: 32
                                                                                }}>
                                                                                {process.ProjectAOINo}
                                                                            </div>
                                                                        </IconButton>
                                                                        </NicerTooltip>
                                                                }
                                                            </TableCell>
                                                            <TableCell padding="none" style={statusColumnStyle}>
                                                                <CalibrationStatusTableIcon
                                                                    // user={this.props.user}
                                                                    isGuestUser={isMemberOfGuestGroups(this.props.token)}
                                                                    calParamsId={process.GPSCalibrationDoc !== undefined ? process.GPSCalibrationDoc._id : null}
                                                                    lmsVersionsMatch={process.GPSCalibrationDoc !== undefined ? currentLMSVersion === process.GPSCalibrationDoc.LMSVersion : false} />
                                                            </TableCell>
                                                            <TableCell padding="none" style={flightIdColumnStyle}>
                                                                <TableEllipsisIcon
                                                                    value={process.ProcessingTemplate}
                                                                    isActive={process.Active}
                                                                    isEoOnly={isEoOnly}
                                                                    isArchived={false}          // Skip it because ARCHIVED status has been fully presented by downstream icons
                                                                />
                                                            </TableCell>
                                                            <TableCell padding="none" style={statusColumnStyle}>
                                                                <Grid
                                                                    item
                                                                    direction="column"
                                                                    style={{
                                                                        width: 50,
                                                                        height: 50,
                                                                        margin: 1,
                                                                        position: "relative"
                                                                    }}
                                                                >
                                                                    <div style={{ position: "absolute", width: "100%", paddingTop: 1, lineHeight: 0.8, fontSize: 10, fontWeight: "bolder", textAlign: "center" }}>GPS &amp; EO</div>
                                                                    <ProcessingStatusTableIcon
                                                                        GPSRuntime={process.GPSRunStopDate && process.GPSRunStartDate ? humanizeDuration(Math.abs(Date.parse(process.GPSRunStopDate) - Date.parse(process.GPSRunStartDate))) : null}
                                                                        // user={this.props.user}
                                                                        isGuestUser={isMemberOfGuestGroups(this.props.token)}
                                                                        isArchived={process.isArchived}
                                                                        qaUser={process.GPSProcQAUser}
                                                                        updateQA={() => { this.updateProcessingValue(process._id, "GPSProcQAUser", user) }}
                                                                        GPSQAIssueDesc={process.GPSQAIssueDesc}
                                                                        GPSQAIssueRes={process.GPSQAIssueRes}
                                                                        updateQAWithErrors={() => { this.updateQAStatus("GPS", process._id, { "GPSProcQAUser": user, "GPSProcStatus": "QA_COMPLETED_WITH_ERRORS" }) }}
                                                                        updateManuallyProcessed={() => { this.updateQAStatus("GPS", process._id, { "GPSProcQAUser": user, "GPSProcStatus": "MANUALLY_PROCESSED" }) }}
                                                                        updateIgnoreProcessing={() => { this.updateQAStatus("GPS", process._id, { "GPSProcQAUser": user, "GPSProcStatus": "IGNORE" }) }}
                                                                        updateSuperseded={(isActive) => { this.updateProcessingValue(process._id, "Active", isActive) }}
                                                                        template={process.ProcessingTemplate}
                                                                        status={(isAnyResubmitted || isWaitingForPollingResponse) ? 'WAITING' : process.GPSProcStatus}
                                                                        attempts={process.GPSProcAttempts}
                                                                        active={process.Active}
                                                                        deletedSuperseded={process.deletedSuperseded}
                                                                        s3Link={process.GPSOutputPath}
                                                                        archivedS3Link={process.archiveS3PathLiDAR}
                                                                        updateStatus={(statusValue) => { this.updateProcessingStatus(process._id, "GPSProcStatus", statusValue) }}
                                                                        resubmit={() => { this.showResubmitDialog(process.SensorID, process.BaseFolder, process._id, process) }}
                                                                        // resubmit={() => { this.showResubmitDialog(process.SensorID, process.BaseFolder, process._id) }}
                                                                        // submitLMS={() => { this.showLMSResubmitDialog(process._id) }}
                                                                        submitLMS={() => { this.showEOResubmitDialog(process._id) }}
                                                                        isAnyRunningJob={isAnyRunningJob}           // used to block 'resubmit job' of upstream icon
                                                                        documentMajorVersion={process.DocumentMajorVersion}
                                                                        GPSRunStopDate={process.GPSRunStopDate}
                                                                        explorerOnClickCallback={() => this.handleExplorerOnClick('GPS', process._id, process.archiveS3PathLiDAR)}
                                                                        isEoOnly={isEoOnly}
                                                                    />
                                                                    {
                                                                        process.CustomProcessing != null &&
                                                                        <div style={{
                                                                            width: 12, height: 12, backgroundColor: "#1a3a69", borderRadius: "50%", border: "2px solid #1a3a69", color: "#FFFFFF",
                                                                            fontWeight: "bold", fontSize: 10, position: "absolute", marginTop: -38, marginLeft: 32
                                                                        }}>{process.CustomProcessing.counter}</div>
                                                                    }
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell padding="none" style={statusColumnStyle}>
                                                                <Grid
                                                                    item
                                                                    direction="column"
                                                                    style={{
                                                                        width: 50,
                                                                        height: 50,
                                                                        margin: 1,
                                                                        position: "relative"
                                                                    }}>
                                                                    <div style={{ position: "absolute", width: "100%", paddingTop: 1, lineHeight: 0.8, fontSize: 10, fontWeight: "bolder", textAlign: "center" }}>LMS</div>
                                                                    <LMSProcessingStatusTableIcon
                                                                        LMSRuntime={process.LMSRunStopDate && process.LMSRunStartDate ? humanizeDuration(Math.abs(Date.parse(process.LMSRunStopDate) - Date.parse(process.LMSRunStartDate))) : null}
                                                                        // user={this.props.user}
                                                                        isGuestUser={isMemberOfGuestGroups(this.props.token)}
                                                                        isArchived={process.isArchived}
                                                                        qaUser={process.LMSProcQAUser}
                                                                        updateQA={() => { this.updateProcessingValue(process._id, "LMSProcQAUser", user) }}
                                                                        LMSQAIssueDesc={process.LMSQAIssueDesc}
                                                                        LMSQAIssueRes={process.LMSQAIssueRes}
                                                                        updateQAWithErrors={() => { this.updateQAStatus("LMS", process._id, { "LMSProcQAUser": user, "LMSProcStatus": "QA_COMPLETED_WITH_ERRORS" }) }}
                                                                        updateManuallyProcessed={() => { this.updateQAStatus("LMS", process._id, { "LMSProcQAUser": user, "LMSProcStatus": "MANUALLY_PROCESSED" }) }}
                                                                        updateIgnoreProcessing={() => { this.updateQAStatus("LMS", process._id, { "LMSProcQAUser": user, "LMSProcStatus": "IGNORE" }) }}
                                                                        template={process.ProcessingTemplate}

                                                                        // status={process.LMSProcStatus}
                                                                        // status={(this.state.resubmitJobProcessingIdList.indexOf(process._id) !== -1) ? 'WAITING' : process.LMSProcStatus}
                                                                        // status={isAnyResubmitted ? 'WAITING' : process.LMSProcStatus}

                                                                        status={(isAnyResubmitted || isWaitingForPollingResponse) ? 'WAITING' : process.LMSProcStatus}
                                                                        active={process.Active}
                                                                        deletedSuperseded={process.deletedSuperseded}
                                                                        s3Link={process.LMSOutputPath}
                                                                        archivedS3Link={process.archiveS3PathLiDAR}
                                                                        resubmit={() => { this.showLMSResubmitDialog(process._id, process) }}
                                                                        cancel={() => { this.showLMSCancelDialog(process._id, process) }}
                                                                        // resubmit={() => { this.showLMSResubmitDialog(process._id) }}
                                                                        updateStatus={(statusValue) => { this.updateProcessingStatus(process._id, "LMSProcStatus", statusValue) }}
                                                                        isAnyRunningJob={isAnyRunningJob}           // used to block 'resubmit job' of upstream icon
                                                                        // submitToNextStage={() => this.props.firstSubmitClusterProcessing}
                                                                        submitToNextStage={() => this.showClusterPipelineResubmitDialog(process._id)}
                                                                        documentMajorVersion={process.DocumentMajorVersion}
                                                                        LMSRunStopDate={process.LMSRunStopDate}
                                                                        explorerOnClickCallback={() => this.handleExplorerOnClick('LMS', process._id, process.archiveS3PathLiDAR)}
                                                                    />
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell padding="none" style={pipelineColumnStyle}>
                                                                {(process.ClusterProcessing
                                                                    && process.ClusterProcessing.length)
                                                                    ? <DynamicClusterStatusIcon
                                                                        // user={this.props.user}
                                                                        process={process}
                                                                        isWaitingForPollingResponse={isWaitingForPollingResponse}
                                                                        // clusterFilter="IP"
                                                                        key={process._id}
                                                                        explorerOnClickCallback={() => this.handleExplorerOnClick('Pipeline', process._id, process.archiveS3PathLiDAR)}
                                                                    />
                                                                    : <div style={{ paddingLfet: 34, textAlign: 'left' }}>
                                                                        <BlockIcon
                                                                            className='icon--primary'
                                                                            style={{ color: '#a3a3a3', width: '26px', height: '26px', paddingLeft: 46, paddingTop: 8 }}       // Have to use inline style here
                                                                        />
                                                                    </div>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )
                                        }

                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10]}
                                                count={queryParams.total}
                                                rowsPerPage={10}
                                                page={queryParams.page - 1}
                                                onChangePage={this.changePage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>

                                <QALogDialog
                                    dialogOpen={this.state.QALogDialogOpen}
                                    onConfirm={(issueDesc, issueRes) => {
                                        this.state.submitCallback(issueDesc, issueRes);
                                    }}
                                    onCancel={() => this.setState({ QALogDialogOpen: false })}
                                />

                                {
                                    (this.state.resubmittedJobProcessingTemplate 
                                            && (this.state.resubmittedJobProcessingTemplate.split('-')[this.state.resubmittedJobProcessingTemplate.split('-').length-1] == 'MiningAI') 
                                            && this.state.resubmittedJobDocumentMajorVersion == 3.2
                                    )
                                        ? <ConfirmationDialog
                                            title="Resubmit Job"
                                            confirmLabel="Confirm"
                                            onCancel={() => { this.setState({ resubmitDialogOpen: false }) }}
                                            onConfirm={() => {
                                                this.setState({ resubmitDialogOpen: false });
                                            }}
                                            open={this.state.resubmitDialogOpen}
                                        >
                                            <p>Resubmission unavailabe due to folder structure changes, contact support.</p>
                                        </ConfirmationDialog>
                                        : <ConfirmationDialog
                                            title="Resubmit Job"
                                            confirmLabel="PROCEED"
                                            onCancel={() => { this.setState({ resubmitDialogOpen: false }) }}
                                            onConfirm={() => {
                                                this.setState({ resubmitDialogOpen: false });
                                                this.state.submitCallback();
                                            }}
                                            open={this.state.resubmitDialogOpen}
                                        >
                                            <p>Are you sure you want reprocess this data?</p>
                                            <p>The existing data (including downstream processing results) will be <span style={{ fontWeight: 'bold' }}>OVERWRITTEN</span></p>
                                        </ConfirmationDialog>
                                }

                                <ConfirmationDialog
                                    title="Cancel LMS"
                                    confirmLabel="PROCEED"
                                    onCancel={() => { this.setState({ cancelDialogOpen: false }) }}
                                    onConfirm={() => {
                                        this.setState({ cancelDialogOpen: false });
                                        this.state.cancelCallback();
                                    }}
                                    open={this.state.cancelDialogOpen}>
                                    <p>Are you sure you want to cancel this LMS processing?</p>
                                    <p>All LMS processing progress will be lost.</p>
                                </ConfirmationDialog>

                                <ConfirmationDialog
                                    title="Update Status"
                                    confirmLabel="PROCEED"
                                    onCancel={() => { this.setState({ markCompletedDialogOpen: false }) }}
                                    onConfirm={() => {
                                        this.setState({ markCompletedDialogOpen: false });
                                        this.state.submitCallback();
                                    }}
                                    open={this.state.markCompletedDialogOpen}>
                                    <p>Are you sure you want to update this status?</p>
                                    <p>Updating the status may impact downstream processing results.</p>
                                </ConfirmationDialog>

                                <Dialog
                                    open={this.state.isValidateArchiveDialogOpen}
                                    PaperProps={{
                                        style: {
                                            backgroundColor: '#808080e6',
                                            boxShadow: 'none',
                                            minHeight: '150px', minWidth: '300px',
                                            borderRadius: 10,
                                        },
                                    }}
                                >
                                    <Grid container direction="column" alignItems="center" justify="center" className='pt-3'>
                                        <CircularProgress />
                                        <div className='pt-5' >Validating archived files...</div>
                                    </Grid>
                                </Dialog>

                                <FlightLineMapDialog
                                    process={this.state.fligtlineDialogProcess || {}}
                                    open={this.state.isFlighLineDialogOpen}
                                    onClose={this.handleFlightLineDialogOnClose} 
                                />

                                <Snackbar
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    open={this.state.isValidateArchiveSnackBarOpen}
                                    autoHideDuration={4000}
                                    onClose={this.handleValidateArchiveSnackBarOnClose}
                                >
                                    <SnackbarContent
                                        style={{ backgroundColor: "#1a3a69E5" }}      // have to use inline style or use withStyle()
                                        message={
                                            <span style={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}>
                                                {this.props.explorerStore.validateArchiveSucceed
                                                    ? this.props.explorerStore.isArchivedSucceed
                                                        ? <>
                                                            <InfoIcon className='pr-1' style={{ opacity: 0.7 }} />
                                                            Archive validation succeed
                                                        </>
                                                        : <>
                                                            <CancelIcon className='pr-1' style={{ opacity: 0.7 }} />
                                                            Archive validation failed
                                                        </>
                                                    : <>
                                                        <CancelIcon className='pr-1' style={{ opacity: 0.7 }} />
                                                        Failed to validate archived files
                                                    </>
                                                }
                                            </span>
                                        }
                                    />
                                </Snackbar>
                            </Grid >
                        </div>
                        <div
                            className='pl-3'
                            style={{ display: (this.props.processingListLoading && !this.state.polling) || this.state.filterLoading ? 'none' : 'inline' }}
                        >
                            <NicerTooltip placement="top" content="Search">
                                <IconButton
                                    color="primary"
                                    style={{ marginBottom: 10 }}
                                    onClick={this.handleOpenFilterDialog}
                                >
                                    <Search />
                                </IconButton>
                            </NicerTooltip>
                        </div>
                    </Grid>
                </div>
                <FilterDialog
                    open={this.state.isFilterDialogOpen}
                    onClose={this.handleFilterDialogOnClose}
                    queryFilters={this.state.queryFilters}
                    updateQueryFiltersCB={this.handleUpdateQueryFilters}
                    selectFilterOptions={{
                        processingTemplates: [...this.state.processingTemplates],
                        projectManagers: [...this.state.projectManagers],
                        sensors: [...this.props.sensorEquipment]
                    }}
                    filterOnFocusIndex={this.state.filterOnFocusIndex}
                />
            </>
        );
    }
}

// export default ProcessingList;

const mapStateToProps = (state, ownProps) => {

    // let projectManagers = ownProps.projectManagers ? ownProps.projectManagers : [];
    // if(state.uniqueValuesFetchSuccess && state.uniqueValuesFetchSuccess["project.ProjectManager"])
    //     projectManagers = state.uniqueValuesFetchSuccess["project.ProjectManager"].map(item => { return { "label": item, "value": item } });

    // let processingTemplates = ownProps.processingTemplates ? ownProps.processingTemplates : [];
    // if(state.uniqueValuesFetchSuccess && state.uniqueValuesFetchSuccess["project.ProcessingTemplate"])
    //     processingTemplates = state.uniqueValuesFetchSuccess["project.ProcessingTemplate"].map(item => { return { "label": item, "value": item } });

    let tokenPayload = state.token ? JSON.parse(atob(state.token.split(".")[1])) : ''
    let user = tokenPayload.given_name ? (tokenPayload.given_name + ' ' + tokenPayload.family_name) : ''

    return {
        token: state.token,
        processingListLoading: state.processingListLoading,
        queryParams: state.processingQueryParams,
        processing: state.processing,
        // processingSubmitLoading: state.processingResubmitLoading,
        processingSubmitSuccessful: state.processingResubmitSuccess && !state.processingResubmitLoading,
        currentLMSVersion: state.appSettings ? state.appSettings.LMSCurrentVersion : null,
        sensorEquipmentListLoading: state.sensorEquipmentListLoading,
        sensorEquipment: state.sensorEquipment ? state.sensorEquipment.map(item => { return { "label": item.Name, "value": item.Name } }) : [],
        projectManagers: state.uniqueValuesFetchSuccess && state.uniqueValuesFetchSuccess["project.ProjectManager"] ?
            state.uniqueValuesFetchSuccess["project.ProjectManager"].map(item => { return { "label": item, "value": item } }) : [],
        processingTemplates: state.uniqueValuesFetchSuccess && state.uniqueValuesFetchSuccess["project.ProcessingTemplate"] ?
            state.uniqueValuesFetchSuccess["project.ProcessingTemplate"].map(item => { return { "label": item, "value": item } }) : [],
        projectManagersIsLoading: state.uniqueValuesFetchLoading,
        projectManagersHasError: state.uniqueValuesFetchHasError,
        user,
        processingResubmitLoadingWithId: state.processingResubmitLoadingWithId,
        processingLMSResubmitLoadingWithId: state.processingLMSResubmitLoadingWithId,
        processingClusterResubmitLoadingWithId: state.processingClusterResubmitLoadingWithId,
        explorerStore: state.objectExplorer
    };
};

// //this is needed to match thre returned result to properties
const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (token) => { dispatch(processingFetchList(token)) },
        setQueryParams: payload => {
            dispatch(processingQueryParams(payload))
            dispatch(processingFetchList())
        },
        resubmitGPSProcessing: (token, jsonData, id) => { dispatch(resubmitProcessing(token, jsonData, id)) },
        resubmitLMSProcessing: (token, id) => { dispatch(resubmitLMSProcessing(token, id)) },
        cancelLMSProcessing: (token, id) => { dispatch(cancelLMSProcessing(token, id)) },

        resubmitGPSEOProcessing: (token, id) => { dispatch(resubmitEOProcessing(token, id)) },

        firstSubmitClusterProcessing: (token, id) => { dispatch(firstSubmitClusterProcessing(token, id)) },
        resubmitClusterProcessing: (token, id, clusterName) => { dispatch(resubmitClusterProcessing(token, id, clusterName)) },
        updateProcessing: (token, id, jsonData, clusterName) => { dispatch(processingUpdate(token, id, jsonData, clusterName)) },
        fetchSensors: (token) => { dispatch(sensorEquipmentFetchList(token)) },
        fetchUniqueValues: (token, collection, field) => { dispatch(uniqueValuesFetch(token, collection, field)) },
        clearAllFilters: () => { dispatch(clearAllFilters(true)) },
        handleValidateArchive: (bucket, prefix) => { dispatch(handleValidateArchive(bucket, prefix)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps/*, mergeProps, { pure: true }*/)(ProcessingList);